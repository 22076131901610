<!-- 矿机 -->

<template>
  <div class="mining">
    <div class="content">
      <!-- <div class="mining-data">
        <div class="mining-data-item f24">
          <div class="word color-999">{{ $i18n.t("mi_wallet_balance") }}</div>
          <div class="num color-333 font-m">5</div>
        </div>
        <div class="mining-data-item f24">
          <div class="word color-999">{{ $i18n.t("mi_bookable_balance") }}</div>
          <div class="num color-333 font-m">{{ userInfo.availableUSD }}</div>
        </div>
        <div class="mining-data-item f24">
          <div class="word color-999">{{ $i18n.t("mi_bookable_range") }}</div>
          <div class="num color-333 font-m">5-10</div>
        </div>
      </div> -->
      <div class="mining-info">
        <!-- <div class="select-line">
          <van-dropdown-menu active-color="#fd4c4c">
            <van-dropdown-item v-model="value1" :options="option1" />
            <van-dropdown-item v-model="value2" :options="option2" />
          </van-dropdown-menu>
        </div> -->
        <div class="mining-block">
          <!-- <div class="ques">
            <img src="../assets/images/icon-quer-nor.svg" alt="" />
          </div> -->
          <swiper
            ref="mining"
            class="mining-box"
            :options="swiperOptions"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
            @ready="handleSwiperReadied"
            @click-slide="handleClickSlide"
          >
            <swiper-slide v-for="(item, index) in miningList" :key="index">
              <img :src="item.imageUrl" alt="" class="swiper-img" />
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="mining-info-list">
          <div class="mining-item">
            <div class="label color-999 f28 font-m">{{ $i18n.t("mi_limitation") }}</div>
            <div class="value color-333 f28 font-m">
              {{ currentItem.min }} ~ {{ currentItem.max }}
            </div>
          </div>
          <div class="mining-item">
            <div class="label color-999 f28 font-m">{{ $i18n.t("mi_earning_rate") }}</div>
            <div class="value color-333 f28 font-m">{{ currentItem.income }}%</div>
          </div>
          <div class="mining-item">
            <div class="label color-999 f28 font-m">{{ $i18n.t("mi_cycle") }}</div>
            <div class="value color-333 f28 font-m">
              {{ currentItem.cycle }} {{ $i18n.t("mi_day") }}
            </div>
          </div>
        </div>
        <div class="btn confirm-btn" @click="handleConfirm">{{ $i18n.t("mi_confirm") }}</div>
      </div>
    </div>
    <van-popup class="mining-popup" round v-model="showOfferBomb">
      <div class="mining-box">
        <div class="close" @click="showOfferBomb = false">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="lemit f28">
          <div class="name color-666">{{ $i18n.t("mi_limitation") }}</div>
          <div class="count">
            <img src="../assets/images/usdc.svg" alt="" />
            <div class="num font-b color-333 font-bold">
              {{ currentItem.min }} ~ {{ currentItem.max }}
            </div>
          </div>
        </div>
        <div class="data">
          <div class="data-item f28">
            <div class="word color-666">{{ $i18n.t("mi_earning_rate") }}</div>
            <div class="price color-333">
              <div class="num font-b color-333 font-bold">{{ currentItem.income }}%</div>
            </div>
          </div>
          <div class="data-item f28">
            <div class="word color-666">{{ $i18n.t("mi_cycle") }}</div>
            <div class="price color-333">
              <div class="num font-b color-333 font-bold">
                {{ currentItem.cycle }} {{ $i18n.t("mi_day") }}
              </div>
            </div>
          </div>
        </div>
        <div class="input-line">
          <div class="label f28 color-666">{{ $i18n.t("mi_input") }}</div>
          <div class="input-box">
            <input class="font-bold color-333 f36" type="text" placeholder="0" v-model="enterNum" />
            <div class="unit-box font-m f28">
              <div class="unit">USDC</div>
              <div class="hr">|</div>
              <div class="all theme-word-color font-bold">{{ $i18n.t("mi_whole") }}</div>
            </div>
          </div>
        </div>
        <div class="tip f24 color-999">
          {{ $i18n.t("gd_tips") }}{{ $i18n.t("mi_tips_content") }}
        </div>
        <div class="btn confirm" @click="buyMining">{{ $i18n.t("mi_confirm") }}</div>
      </div>
    </van-popup>
    <tab-bar :currentIndex="currentTab" @changeTab="changeTab" />
  </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
export default {
  components: {
    TabBar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      showOfferBomb: false,
      currentTab: 2,
      value1: 0,
      value2: "1",
      miningList: [],
      option1: [
        {text: "LV 0", value: 0},
        {text: "LV 1", value: 1},
        {text: "LV 2", value: 2},
      ],
      option2: [
        {text: "50 ~ 150", value: "1"},
        {text: "150 ~ 500", value: "2"},
        {text: "500 ~ 1000", value: "3"},
      ],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      enterNum: null,
      currentIndex: 0,
      currentItem: {
        min: 0,
        max: 0,
        cycle: 0,
        income: 0,
      },
      userInfo: {
        availableUSD: 0,
        walletBalance: 0,
      },
    };
  },
  created() {
    this.getUserInfo();
    this.getList();
  },
  methods: {
    handleConfirm() {
      this.$toast(this.$i18n.t("msg_functional_progress"));
      this.showOfferBomb = false;
    },
    handleSwiperReadied(sw) {
      this.currentIndex = sw.activeIndex;
    },
    handleClickSlide(index) {
      this.currentIndex = index;
    },
    getUserInfo() {
      this.$http.getUserInfo().then((res) => {
        if (res.code === 0) {
          this.userInfo = res.data;
        }
      });
    },
    async getList() {
      const params = {
        page: 1,
        pageSize: 1000,
      };
      const res = await this.$http.getMiningList(params);
      this.miningList = res.data.list || [];
      this.currentItem = this.miningList[this.currentIndex] || {};
    },
    async buyMining() {
      const data = {
        amount: parseFloat(this.enterNum),
        id: this.currentItem.id,
      };
      if (!this.enterNum) {
        this.$toast(this.$i18n.t("mi_empty_tips"));
      }
      const res = await this.$http.postBuyMining(data);
      if (res.code === 0) {
        this.$toast(this.$i18n.t(res.msg));
      }
    },
    changeTab(index) {
      this.currentTab = index;
    },
  },
};
</script>

<style lang="less">
.mining {
  .van-dropdown-item {
    z-index: 888 !important;
  }
  padding-bottom: 2rem;
  .content {
    .mining-data {
      padding: 1.36rem 0.48rem 0.6rem 0.48rem;
      background: #f9f9f9;
      display: flex;
      .mining-data-item {
        flex: 1;
        padding: 0.16rem 0.16rem;
        margin-right: 0.2rem;
        background: #fff;
        border-radius: 0.3rem;
        .word {
          line-height: 0.4rem;
          white-space: nowrap;
        }
        .num {
          line-height: 0.44rem;
        }
      }
      .mining-data-item:last-child {
        margin-right: 0;
      }
    }
    .mining-info {
      padding: 0.48rem;
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(50%);
      .select-line {
        .van-dropdown-menu {
          .van-dropdown-menu__bar {
            box-shadow: none;
            height: 40px;
            .van-dropdown-menu__item {
              border-radius: 40px;
              border: 1px solid #eee;
              margin-right: 0.3rem;
              .van-dropdown-menu__title {
                .van-ellipsis {
                  padding-right: 10px;
                  font-family: myMidFont;
                  font-size: 0.28rem;
                  color: #333;
                }
              }
            }
            .van-dropdown-menu__item:last-child {
              margin-right: 0;
              .van-dropdown-menu__title {
                .van-ellipsis {
                  padding-left: 30px;
                  background: url("../assets/images/usdc.svg") no-repeat left center;
                  background-size: 24px 24px;
                }
              }
            }
          }
        }
      }
      .mining-block {
        // padding: 0.3rem 0;
        position: relative;
        .ques {
          position: absolute;
          right: 0;
          top: 0.32rem;
        }
        .mining-box {
          display: flex;
          justify-content: center;
          min-height: 250px;
          img {
            width: 66.7vw;
          }
        }
        .swiper-button-prev {
          width: 0.5rem;
          height: 0.5rem;
          background: url("../assets/images/swiper-left.png");
          background-size: 100%;
          left: -0.1rem;
        }
        .swiper-button-next {
          width: 0.5rem;
          height: 0.5rem;
          background: url("../assets/images/swiper-right.png");
          background-size: 100%;
          right: -0.1rem;
        }
        .swiper-button-prev::after,
        .swiper-button-next::after {
          display: none;
        }
      }
      .mining-info-list {
        .mining-item {
          display: flex;
          justify-content: space-between;
          line-height: 0.8rem;
        }
      }
    }
    .confirm-btn {
      width: 100%;
      margin-top: 0.6rem;
    }
  }
  .mining-popup {
    width: 86%;
    padding-bottom: 0.48rem;
    .mining-box {
      padding: 0 0.48rem;
      padding-top: 0.8rem;
      position: relative;
      .close {
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
      }
      .lemit {
        text-align: left;
        margin-bottom: 0.3rem;
        .name {
          line-height: 0.4rem;
          margin-bottom: 0.2rem;
        }
        .count {
          display: flex;
          img {
            width: 0.32rem;
            height: 0.32rem;
            margin-top: 0.04rem;
            margin-right: 0.1rem;
          }
          .num {
            line-height: 0.4rem;
          }
        }
      }
      .data {
        display: flex;
        margin-bottom: 0.4rem;
        .data-item {
          flex: 1;
          .word {
            margin-bottom: 0.12rem;
          }
        }
      }
      .input-line {
        text-align: left;
        .label {
          line-height: 0.4rem;
          margin-bottom: 0.2rem;
        }
        .input-box {
          border: 1px solid #fd4c4c;
          border-radius: 0.2rem;
          padding: 0.1rem 0.1rem 0.1rem 0.6rem;
          display: flex;
          justify-content: space-between;
          background: url("../assets/images/usdc.svg") no-repeat 0.1rem center;
          background-size: 0.4rem;
          input {
            border: none;
            outline: none;
            height: 0.6rem;
            width: calc(100% - 1.8rem);
          }
          .unit-box {
            width: 1.8rem;
            display: flex;
            height: 0.6rem;
            line-height: 0.6rem;
            .hr {
              margin: 0 0.1rem;
            }
          }
        }
      }
      .tip {
        line-height: 0.4rem;
        text-align: justify;
        margin-top: 0.2rem;
      }
      .confirm {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-top: 0.6rem;
      }
    }
  }
}
</style>
